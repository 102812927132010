import { Container } from '@mui/material';
import React from 'react';

const PageContainer = ({ children, sx, ...restProps }) => (
  <Container sx={{ height: '100%', ...sx }} maxWidth>
    {children}
  </Container>
);

export default PageContainer;
