import React from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import PageContainer from '../components/PageContainer';
import BackToTop from '../components/BackToTop';
import BEEImg from '../assets/bbbee-750x400.jpg';
import styled from '@emotion/styled';

const BEEImage = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
`;

const About = ({ backToTop }) => (
  <PageContainer>
    <BackToTop backToTop={backToTop} />
    <Container
      maxWidth
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        mt: {
          xs: -10,
        },
      }}
    >
      <Paper sx={{ width: '100%', padding: 5 }}>
        <Typography variant="h4" color="#4e4e4e" sx={{ mb: 2 }}>
          About Us
        </Typography>

        <Grid container spacing={{ xs: 0, sm: 2 }}>
          <Grid
            item
            xs={12}
            sm={8}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <ul>
              <li>
                100% black female owned trading business established in 2002
              </li>
              <li>Clasco Marketing is a Level 1 BBBEE QSE</li>
              <li>
                Until 2016, Clasco was a sister company of Orbichem
                Petrochemicals Pty Ltd, a local BBBEE base oil distributor
                company
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}>
            <BEEImage src={BEEImg} alt="Clasco Marketing" />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  </PageContainer>
);

export default About;
