import React from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import MovingComponent from 'react-moving-text';
import PageContainer from '../components/PageContainer';
import LogoImg from '../assets/logo.png';
import styled from '@emotion/styled';
import { ArrowDownward } from '@mui/icons-material';

const ClascoLogoImg = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
`;

const StyledMovingText = styled(MovingComponent)`
  width: 100%;
  max-width: 600px;
  height: auto;
`;

const Landing = ({ onPageChange }) => (
  <PageContainer>
    <Container
      maxWidth
      sx={{
        position: 'absolute',
        bottom: 50,
        left: 0,
        textAlign: 'center',
      }}
    >
      <MovingComponent
        type="bounce"
        duration="2000ms"
        delay="0s"
        direction="normal"
        timing="ease-in-out"
        iteration="infinite"
        fillMode="both"
      >
        <ArrowDownward color="#4e4e4e" />
      </MovingComponent>
      <Typography variant="h6" color="#4e4e4e">
        Scroll Down
      </Typography>
    </Container>
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        mt: 2,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button size="large" onClick={() => onPageChange(1)}>
            Business Overview
          </Button>
        </Grid>
        <Grid item xs={4} onClick={() => onPageChange(2)}>
          <Button size="large">About Us</Button>
        </Grid>
        <Grid item xs={4}>
          <Button size="large" onClick={() => onPageChange(3)}>
            Contact Us
          </Button>
        </Grid>
      </Grid>
    </Container>
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        mt: -10,
      }}
    >
      <StyledMovingText
        type="fadeIn"
        duration="1000ms"
        delay="0s"
        direction="normal"
        timing="ease-in"
        iteration="1"
        fillMode="none"
      >
        <ClascoLogoImg src={LogoImg} alt="Clasco Marketing" />
      </StyledMovingText>
    </Container>
  </PageContainer>
);

export default Landing;
