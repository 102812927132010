import React from 'react';
import { Container, Grid, Paper, Stack, Typography } from '@mui/material';
import BackToTop from '../components/BackToTop';
import PageContainer from '../components/PageContainer';

const Contacts = ({ backToTop }) => (
  <PageContainer>
    <BackToTop backToTop={backToTop} />
    <Container
      maxWidth
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        mt: {
          xs: -10,
        },
      }}
    >
      <Paper sx={{ width: '100%', padding: 5 }}>
        <Typography variant="h4" color="#4e4e4e" sx={{ mb: 2 }}>
          Contact Us
        </Typography>
        <Grid container>
          <Grid item sx={12} sm={6}>
            <Stack spacing={2}>
              <div>
                <Stack>
                  <strong>Physical Address</strong>
                  <span>38 Welgemoed Greens</span>
                  <span>Sturke Rd</span>
                  <span>Welgemoed</span>
                  <span>7530</span>
                </Stack>
              </div>
              <div>
                <Stack>
                  <strong>Postal Address</strong>
                  <span>PO Box 5288</span>
                  <span>Tygervalley</span>
                  <span>7536</span>
                </Stack>
              </div>
              <Stack>
                <strong>Company Registration No:</strong>
                <span>2002/068735/23</span>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sx={12} sm={6}>
            <Stack>
              <strong>Lydia Classen</strong>
              <div>
                <span>Email: {'  '}</span>
                <a href="mailto:lydia@clasco.co.za">lydia@clasco.co.za</a>
              </div>
              <div>
                <span>Cell: {'  '}</span>
                <a href="tel:+27 83 735 8736">+27 83 735 8736</a>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  </PageContainer>
);

export default Contacts;
