import React, { useState } from 'react';
import ReactPageScroller from 'react-page-scroller';
import About from '../About';
import BuisnessOverview from '../BuisnessOverview';
import Contacts from '../Contacts';
import Landing from '../Landing';

const MainPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (number) => {
    setCurrentPage(number);
  };

  return (
    <ReactPageScroller
      pageOnChange={handlePageChange}
      customPageNumber={currentPage}
    >
      <Landing onPageChange={handlePageChange} />
      <BuisnessOverview backToTop={() => setCurrentPage(0)} />
      <About backToTop={() => setCurrentPage(0)} />
      <Contacts backToTop={() => setCurrentPage(0)} />
    </ReactPageScroller>
  );
};
export default MainPage;
