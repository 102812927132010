import { Container, Grid, Paper, Typography } from '@mui/material';

import React from 'react';
import PageContainer from '../components/PageContainer';
import OilTankerImg from '../assets/pexels-robert-so-13571992.jpg';
import styled from '@emotion/styled';
import BackToTop from '../components/BackToTop';

const OilTanker = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
`;

const BuisnessOverview = ({ backToTop }) => (
  <PageContainer>
    <BackToTop backToTop={backToTop} />
    <Container
      maxWidth
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        mt: {
          xs: -10,
        },
      }}
    >
      <Paper sx={{ width: '100%', padding: 5 }}>
        <Typography variant="h4" color="#4e4e4e" sx={{ mb: 2 }}>
          Business Overview
        </Typography>

        <Grid container spacing={{ xs: 0, sm: 2 }}>
          <Grid item xs={12} sm={4}>
            <OilTanker src={OilTankerImg} alt="Clasco Marketing" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <ul>
              <li>
                Clasco Marketing is a distributor of base oils, solvents and
                other speciality chemicals
              </li>
              <li>
                Has direct access to top refining sources in the international
                market
              </li>
              <li>
                Current sales cover South Africa and neighbouring countries
                markets
              </li>
              <li>
                Is a partner of Penthol SA, a leading Swiss based,
                multi-national petrochemical trading company
              </li>
              <li>
                Is recognised for its management experience, product and
                logistics expertise, customer-centric approach and authentic
                empowerment credentials
              </li>
            </ul>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  </PageContainer>
);

export default BuisnessOverview;
