import MainPage from './pages/main/Main';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#c22a2e',
    },
  },
  typography: {
    fontFamily: '"Nunito","Roboto", "Helvetica","Arial",sans-serif',
  },
});

const App = () => (
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainPage />
    </ThemeProvider>
  </>
);

export default App;
