import { ArrowUpward } from '@mui/icons-material';
import { Button, Container, Stack, Typography } from '@mui/material';
import React from 'react';

const BackToTop = ({ backToTop }) => (
  <Container
    maxWidth
    sx={{
      position: 'relative',
      top: 10,
      textAlign: 'right',
    }}
  >
    <Button
      onClick={backToTop}
      variant="outlined"
      size="small"
      sx={{ textAlign: 'center' }}
    >
      <Stack sx={{ textAlign: 'center' }} spacing={0}>
        <ArrowUpward />

        <Typography>Top</Typography>
      </Stack>
    </Button>
  </Container>
);

export default BackToTop;
